import {
  FieldValues,
  UseFormRegister,
  UseFormWatch,
  Path,
} from 'react-hook-form';
import classNames from 'classnames';
import { RatingOption } from 'types';
import styles from './FeedbackRating.module.scss';

interface FeedbackRatingProps<T extends FieldValues> extends RatingOption {
  register: UseFormRegister<T>;
  error?: boolean;
  watch: UseFormWatch<T>;
}

const FeedbackRating = <T extends FieldValues>({
  field,
  title,
  description,
  error,
  register,
  watch,
}: FeedbackRatingProps<T>) => {
  const watchFields = watch();

  return (
    <div className={styles.root}>
      <legend
        className={classNames(styles.legend, {
          [styles.isRequired]: error,
        })}
      >
        {title}
      </legend>
      <span className={styles.description}>{description}</span>
      <div className={styles.score}>
        {Array.from({ length: 10 }, (_, i) => i + 1).map((value) => (
          <span key={value}>
            <input
              className={styles.radio}
              type="radio"
              id={`${field}-${value}`}
              value={value}
              {...register(field as Path<T>, {
                required: true,
              })}
            />
            <label
              className={classNames(styles.radioLabel, {
                [styles.active]: watchFields[field] === value.toString(),
              })}
              htmlFor={`${field}-${value}`}
            >
              {value}
            </label>
          </span>
        ))}
      </div>
    </div>
  );
};

export default FeedbackRating;
