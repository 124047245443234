import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface sessionState {
  topicId: string;
}

const initialState: sessionState = {
  topicId: '',
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setTopicId: (state, { payload }: PayloadAction<string>) => {
      state.topicId = payload;
    },
  },
});

export const sessionState = (state: RootState) => state.session;

export const { setTopicId } = sessionSlice.actions;

export default sessionSlice.reducer;
