import { configureStore } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from './hooks';
import authReducer, {
  setUserName,
  setVendor,
  authState,
} from './slices/authSlice';
import sessionReducer, {
  setTopicId,
  sessionState,
} from './slices/sessionSlice';
import { sessionsApi } from './api/sessionsApi';
import { questionsApi } from './api/questionsApi';
import { metadataApi } from './api/metadataApi';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    session: sessionReducer,
    [sessionsApi.reducerPath]: sessionsApi.reducer,
    [questionsApi.reducerPath]: questionsApi.reducer,
    [metadataApi.reducerPath]: metadataApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([
      sessionsApi.middleware,
      questionsApi.middleware,
      metadataApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export {
  useAppDispatch,
  useAppSelector,
  setUserName,
  setVendor,
  authState,
  setTopicId,
  sessionState,
};
