import {
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
  ForwardedRef,
  useEffect,
} from 'react';
import { Session, Question, ChatListMethods } from 'types';
import Message from 'components/Message';
import styles from './ChatList.module.scss';

interface ChatListProps {
  currentSession: Session;
  questions: Question[];
}

const ChatList = (
  { currentSession, questions }: ChatListProps,
  ref: ForwardedRef<ChatListMethods>
) => {
  const refAnchor = useRef<HTMLDivElement>(null);

  const scrollUp = async (delay = 50, instant = false) => {
    setTimeout(() => {
      if (refAnchor.current) {
        if (instant) {
          refAnchor.current.scrollIntoView({
            behavior: 'auto',
            inline: 'start',
          });
        } else {
          refAnchor.current.scrollIntoView({
            behavior: 'smooth',
            inline: 'nearest',
          });
        }
      }
    }, delay);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        instantScrollIntoView(delay = 50) {
          scrollUp(delay, true);
        },

        smoothScrollIntoView(delay = 50) {
          scrollUp(delay, false);
        },
      };
    },
    []
  );

  useEffect(() => {
    scrollUp(5, true);
  }, []);

  const { user, created_at, session_length } = currentSession;

  const invitationMessage = useMemo(
    () => `Hi ${user}, I am ready to answer your questions.`,
    [currentSession]
  );

  return (
    <div className={styles.root}>
      <Message
        type="incoming"
        message={invitationMessage}
        createdAt={created_at}
      />

      {questions.map(
        ({ id, user_input, model_response, created_at }: Question, index) => (
          <div key={id} className={styles.messagesWrapper}>
            <Message
              key={`${id}-outcoming`}
              type="outcoming"
              message={user_input}
              createdAt={created_at}
            />

            {model_response && (
              <Message
                key={`${id}-incoming`}
                type="incoming"
                message={model_response}
                order={`${index + 1}/${session_length}`}
                createdAt={created_at}
              />
            )}
          </div>
        )
      )}

      <div ref={refAnchor} />
    </div>
  );
};

export default forwardRef<ChatListMethods, ChatListProps>(ChatList);
