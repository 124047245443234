import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateQuestionMutation } from 'store/api/questionsApi';
import { useGetSessionByIdQuery } from 'store/api/sessionsApi';
import { Question, ChatListMethods } from 'types';
import { NEW_MESSAGE_ID } from 'constants/general';
import ChatBar from 'components/ChatBar';
import Feedback from 'components/Feedback';
import ChatList from 'components/ChatList';
import ChatForm from 'components/ChatForm';
import styles from './Conversation.module.scss';

const Conversation = () => {
  const ref = useRef<ChatListMethods>(null);
  const { sessionId } = useParams();

  const [newQuestion, setNewQuestion] = useState<Question | null>(null);
  const [sessionQuestions, setSessionQuestions] = useState<Question[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    data: currentSession,
    isLoading: isLoadingSession,
    isFetching: isFetchingSession,
    isError,
  } = useGetSessionByIdQuery(sessionId || '', {
    skip: !sessionId,
  });

  const [createQuestion, { isLoading: isLoadingCreate }] =
    useCreateQuestionMutation();

  useEffect(() => {
    if (!currentSession || currentSession.questions.length === 0) {
      setSessionQuestions([]);
      return;
    }

    const { questions } = currentSession;

    setSessionQuestions(questions);
    ref.current?.instantScrollIntoView();

    const lastQuestion = questions[questions.length - 1];
    if (lastQuestion.status !== 'completed') {
      setNewQuestion(lastQuestion);
    }
  }, [currentSession]);

  const handleMessageSend = async (value: string) => {
    if (!sessionId) {
      return;
    }

    setSessionQuestions((state) => [
      ...state,
      { id: NEW_MESSAGE_ID, user_input: value } as Question,
    ]);

    ref.current?.smoothScrollIntoView();

    try {
      setLoading(true);
      const result = await createQuestion({
        user_input: value,
        session_id: sessionId,
      }).unwrap();
      setLoading(false);

      setSessionQuestions((state) => [
        ...state.slice(0, state.length - 1),
        result,
      ]);

      ref.current?.smoothScrollIntoView();

      setNewQuestion(result);
    } catch (error) {
      console.error(error);
    }
  };

  if (isError) {
    return (
      <div className={styles.root}>
        <ChatBar isLoading={isLoading} />
        <div className={styles.notFoundContainer}>Session not found</div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <ChatBar isLoading={isLoading} />

      <div className={styles.container}>
        <div className={styles.chatContainer}>
          {sessionId &&
          currentSession &&
          !isLoadingSession &&
          !isFetchingSession ? (
            <ChatList
              ref={ref}
              currentSession={currentSession}
              questions={sessionQuestions}
            />
          ) : (
            <div />
          )}

          <ChatForm
            onSubmit={handleMessageSend}
            isDisabled={
              !!newQuestion ||
              isLoadingCreate ||
              currentSession?.status === 'completed'
            }
          />
        </div>

        <Feedback
          currentQuestion={newQuestion}
          questionsLength={
            sessionQuestions[sessionQuestions.length - 1]?.id === NEW_MESSAGE_ID
              ? sessionQuestions.length - 1
              : sessionQuestions.length
          }
          sessionLength={currentSession?.session_length || 0}
          onResetFeedback={() => setNewQuestion(null)}
          onResetSession={() => setSessionQuestions([])}
          setLoading={setLoading}
        />
      </div>
    </div>
  );
};

export default Conversation;
