import { useMemo } from 'react';
import { FieldValues, Path, Control, Controller } from 'react-hook-form';
import Select from 'react-select';
import classNames from 'classnames';
import { SelectOption } from 'types';
import { getCustomStyles } from 'helpers';
import { correctIntentOptions } from 'constants/feedback';
import styles from './FeedbackIntentSelect.module.scss';

interface FeedbackIntentSelectProps<T extends FieldValues> {
  error?: boolean;
  control: Control<T, any>;
}

const FeedbackIntentSelect = <T extends FieldValues>({
  control,
  error,
}: FeedbackIntentSelectProps<T>) => {
  const options: SelectOption[] = useMemo(
    () =>
      correctIntentOptions.map((item) => ({
        value: item,
        label: item,
      })) || [],
    [correctIntentOptions]
  );

  return (
    <div className={styles.root}>
      <legend
        className={classNames(styles.legend, {
          [styles.isRequired]: error,
        })}
      >
        What is the correct Intent?
      </legend>

      <Controller
        name={'correct_intent' as Path<T>}
        control={control}
        rules={{
          validate: (value, formValues) => {
            if (formValues.is_intent_correct === 'No' && !value) {
              return false;
            }
            return true;
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Select
            className={styles.select}
            options={options}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange={(data: SelectOption) => onChange(data.value)}
            value={options.find((item) => item.value === value)}
            styles={getCustomStyles<SelectOption>()}
          />
        )}
      />
    </div>
  );
};

export default FeedbackIntentSelect;
