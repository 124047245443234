import { NavLink } from 'react-router-dom';
import { ChatsCircle } from '@phosphor-icons/react';
import Logo from 'images/head.svg';
import { routes } from 'constants/routes';
import styles from './AppBar.module.scss';

const SVG_SIZE = 24;

const AppBar = () => {
  return (
    <div className={styles.root}>
      <img src={Logo} alt="Ninja Tech AI Logo" />

      <NavLink
        to={routes.manageTasks}
        className={({ isActive }) =>
          isActive ? styles.activeLink : styles.link
        }
      >
        <span className={styles.icon}>
          <ChatsCircle size={SVG_SIZE} color="currentColor" weight="light" />
        </span>
        <span className={styles.linkTitle}>Chat</span>
      </NavLink>
    </div>
  );
};

export default AppBar;
