import { useMemo } from 'react';
import Select from 'react-select';
import { useGetTopicsQuery } from 'store/api/metadataApi';
import {
  useAppDispatch,
  useAppSelector,
  sessionState,
  setTopicId,
} from 'store';
import { SelectOption } from 'types';
import { getCustomStyles } from 'helpers';
import styles from './TopicSelect.module.scss';

interface TopicSelectProps {
  isDisabled?: boolean;
}

const TopicSelect = ({ isDisabled }: TopicSelectProps) => {
  const dispatch = useAppDispatch();
  const { topicId } = useAppSelector(sessionState);
  const { data: topicData, isLoading } = useGetTopicsQuery();

  const topicOptions: SelectOption[] = useMemo(
    () =>
      topicData
        ?.filter((item) => item.answered_times < item.desired_answered_times)
        .map((item) => ({
          value: item.id,
          label: item.display_name,
        })) || [],
    [topicData]
  );

  const selectValue = useMemo(
    () => topicOptions.find((item) => item.value === topicId),
    [topicOptions, topicId]
  );

  const handleChange = ({ value }: SelectOption) => {
    dispatch(setTopicId(value));
  };

  return (
    <Select
      className={styles.root}
      options={topicOptions}
      isLoading={isLoading}
      value={selectValue || null}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={handleChange}
      styles={getCustomStyles<SelectOption>()}
      isDisabled={isDisabled}
    />
  );
};

export default TopicSelect;
