import { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { PaperPlaneRight, X } from '@phosphor-icons/react';
import styles from './ChatForm.module.scss';

const SVG_SIZE = 24;
const MIN_ROWS = 1;
const MAX_ROWS = 2;

interface ChatFormProps {
  isDisabled: boolean;
  onSubmit: (value: string) => void;
}

const ChatForm = ({ isDisabled, onSubmit }: ChatFormProps) => {
  const [message, setMessage] = useState<string>('');

  const handleClearInput = () => {
    setMessage('');
  };

  const handleSubmit = () => {
    if (!message) {
      return;
    }

    onSubmit(message);
    handleClearInput();
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (isDisabled) {
      e.preventDefault();
      return;
    }
    if (e.key === 'Enter' && !e.shiftKey && !isDisabled) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.form}>
        <TextareaAutosize
          placeholder="Start your conversation here"
          className={styles.textarea}
          minRows={MIN_ROWS}
          maxRows={MAX_ROWS}
          value={message}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />

        <div className={styles.actionButtons}>
          {message && (
            <button
              className={styles.clearButton}
              type="reset"
              onClick={handleClearInput}
            >
              <X size={SVG_SIZE} color="currentColor" />
            </button>
          )}

          <button
            className={styles.submitButton}
            type="submit"
            disabled={isDisabled || !message}
            onClick={handleSubmit}
          >
            <PaperPlaneRight
              size={SVG_SIZE}
              color="currentColor"
              weight="fill"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatForm;
