import { useEffect, CSSProperties } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  useAppDispatch,
  useAppSelector,
  authState,
  sessionState,
  setTopicId,
} from 'store';
import {
  useCreateSessionMutation,
  useGetSessionByIdQuery,
} from 'store/api/sessionsApi';
import UserNameInput from 'components/UserNameInput';
import TopicSelect from 'components/TopicSelect';
import styles from './ChatBar.module.scss';

interface ChatBarProps {
  isLoading: boolean;
}

const ChatBar = ({ isLoading }: ChatBarProps) => {
  const navigate = useNavigate();
  const { sessionId } = useParams();

  const dispatch = useAppDispatch();
  const { vendor, userName } = useAppSelector(authState);
  const { topicId } = useAppSelector(sessionState);

  const {
    data: currentSession,
    isLoading: isLoadingSession,
    isFetching: isFetchingSession,
  } = useGetSessionByIdQuery(sessionId || '', {
    skip: !sessionId,
  });

  const [createSession, { isLoading: isLoadingSessionCreate }] =
    useCreateSessionMutation();

  useEffect(() => {
    if (!sessionId) {
      dispatch(setTopicId(''));
      return;
    }

    if (currentSession?.topic && !isFetchingSession) {
      dispatch(setTopicId(currentSession.topic));
    }
  }, [sessionId, currentSession, isFetchingSession]);

  const override: CSSProperties = {
    display: 'block',
    color: '#0cc5ff',
    position: 'absolute',
    top: '71px',
    left: '0',
    right: '0',
    opacity: '0.5',
  };

  const handleStart = async () => {
    const body = {
      topic: topicId,
      user: userName,
      vendor,
    };

    try {
      const { id } = await createSession(body).unwrap();
      navigate(`${id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <UserNameInput />

        <div className={styles.selectorWrapper}>
          <p>Choose a topic:</p>
          <TopicSelect isDisabled={!!sessionId} />
        </div>
      </div>

      <div className={styles.wrapper}>
        <button
          disabled={!!sessionId || !topicId || !userName}
          onClick={handleStart}
          className={styles.button}
        >
          Start
        </button>

        <button onClick={handleSignOut} className={styles.button}>
          Sign Out
        </button>
      </div>

      <BarLoader
        color="#0cc5ff"
        loading={isLoading || isLoadingSessionCreate || isLoadingSession}
        cssOverride={override}
        width="100%"
        height="2px"
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default ChatBar;
