import { Routes, Route, Navigate } from 'react-router-dom';
import { routes } from 'constants/routes';
import ChatPage from 'pages/ChatPage';
import {
  withAuthenticator,
  WithAuthenticatorOptions,
  WithAuthenticatorProps,
} from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { useAppDispatch, setUserName, setVendor } from 'store';
import { storageKeys, getStringFromLocalStorage } from 'helpers';

Amplify.configure({
  Auth: {
    region: `${process.env.REACT_APP_COGNITO_REGION}`,
    userPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID}`,
    oauth: {
      domain: `${process.env.REACT_APP_COGNITO_OATH_DOMAIN}`, // TODO make dynamic based on stage.
      scope: ['profile', 'email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${process.env.REACT_APP_NINJA_UI_URL}`,
      redirectSignOut: `${process.env.REACT_APP_NINJA_UI_URL}`,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

const App = ({ user }: WithAuthenticatorProps) => {
  const dispatch = useAppDispatch();

  const vendor = user?.attributes ? user.attributes['custom:vendor'] : '';
  const userName = getStringFromLocalStorage(storageKeys.USER_NAME);

  dispatch(setVendor(vendor));
  if (userName) {
    dispatch(setUserName(userName));
  }

  return (
    <Routes>
      <Route path={routes.manageTasks}>
        <Route index element={<ChatPage />} />
        <Route path={`:sessionId`} element={<ChatPage />} />
      </Route>
      <Route path="/" element={<Navigate to={routes.manageTasks} replace />} />
      <Route path="*" element={<div>Page not found</div>} />
    </Routes>
  );
};

export default withAuthenticator(App, {
  hideSignUp: true,
} as WithAuthenticatorOptions);
