export const storageKeys = {
  USER_NAME: 'userName',
};

export const setItemToLocalStorage = (
  key: string,
  value: string | object
): void => {
  const storageValue =
    typeof value === 'string' ? value : JSON.stringify(value);

  typeof window !== 'undefined' && localStorage.setItem(key, storageValue);
};

export const getStringFromLocalStorage = (key: string): string | null => {
  return typeof window !== 'undefined' ? localStorage.getItem(key) : null;
};
