import dayjs from 'dayjs';
import { FeedbackFormData, FeedbackData } from 'types';

export const formatDateToString = (date: string) => {
  const dateToday = dayjs().format('YYYY-MM-DD');
  const incomingDate = dayjs(date).format('YYYY-MM-DD');
  return dateToday === incomingDate
    ? dayjs(date).format('HH:mm A')
    : dayjs(date).format('DD MMM HH:mm A');
};

export const prepareFeedbackData = (data: FeedbackFormData): FeedbackData => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    acc[key as keyof FeedbackData] = isNaN(Number(value))
      ? value
      : Number(value);
    return acc;
  }, {} as FeedbackData);
};
