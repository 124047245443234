import { useAppDispatch, useAppSelector, setUserName, authState } from 'store';
import { storageKeys, setItemToLocalStorage } from 'helpers';
import styles from './UserNameInput.module.scss';

const UserNameInput = () => {
  const dispatch = useAppDispatch();
  const { userName } = useAppSelector(authState);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    setItemToLocalStorage(storageKeys.USER_NAME, value);
    dispatch(setUserName(value));
  };

  return (
    <input
      className={styles.root}
      placeholder="Enter your name"
      value={userName}
      onChange={handleChange}
    />
  );
};

export default UserNameInput;
