import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface AuthState {
  userName: string;
  vendor: string;
}

const initialState: AuthState = {
  userName: '',
  vendor: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserName: (state, { payload }: PayloadAction<string>) => {
      state.userName = payload;
    },
    setVendor: (state, { payload }: PayloadAction<string>) => {
      state.vendor = payload;
    },
  },
});

export const authState = (state: RootState) => state.auth;

export const { setUserName, setVendor } = authSlice.actions;

export default authSlice.reducer;
