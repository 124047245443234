import { useMemo, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useCreateFeedbackMutation } from 'store/api/questionsApi';
import { useCompleteSessionMutation } from 'store/api/sessionsApi';
import { metadataApi } from 'store/api/metadataApi';
import { useAppDispatch, setTopicId } from 'store';
import { FeedbackFormData, Feedback as FeedbackRequest, Question } from 'types';
import { prepareFeedbackData } from 'helpers';
import { ratingOptions, isIntentCorrectOptions } from 'constants/feedback';
import { routes } from 'constants/routes';
import FeedbackRating from 'components/FeedbackRating';
import FeedbackIntentSelect from 'components/FeedbackIntentSelect';
import styles from './Feedback.module.scss';

const MIN_TEXTAREA_ROWS = 3;

interface FeedbackProps {
  currentQuestion: Question | null;
  questionsLength: number;
  sessionLength: number;
  onResetFeedback: () => void;
  onResetSession: () => void;
  setLoading: (value: boolean) => void;
}

const Feedback = ({
  currentQuestion,
  questionsLength,
  sessionLength,
  onResetFeedback,
  onResetSession,
  setLoading,
}: FeedbackProps) => {
  const { sessionId } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset: resetForm,
    control,
    watch,
    resetField,
  } = useForm<FeedbackFormData>();

  const isIntentCorrectValue = watch('is_intent_correct');

  const [createFeedback] = useCreateFeedbackMutation();
  const [completeSession] = useCompleteSessionMutation();

  const questionsLeft: number = useMemo(
    () => sessionLength - questionsLength,
    [sessionLength, questionsLength]
  );

  useEffect(() => {
    if (isIntentCorrectValue === 'Yes') {
      resetField('correct_intent');
    }
  }, [isIntentCorrectValue, resetField]);

  const handleSessionComplete = async () => {
    if (!sessionId) {
      return;
    }

    try {
      setLoading(true);
      navigate(routes.manageTasks);
      onResetSession();
      await completeSession(sessionId).unwrap();
      setLoading(false);
      dispatch(metadataApi.util.resetApiState());
      dispatch(setTopicId(''));
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (formData: FeedbackFormData) => {
    if (!sessionId || !currentQuestion) {
      return;
    }

    const { ideal_response, is_intent_correct } = formData;

    const body: FeedbackRequest = {
      session_id: sessionId,
      ...prepareFeedbackData(formData),
      ideal_response: ideal_response || '',
      is_intent_correct: is_intent_correct === 'Yes',
    };

    try {
      setLoading(true);
      await createFeedback({ id: currentQuestion.id, body }).unwrap();
      setLoading(false);
      resetForm();
      onResetFeedback();

      if (questionsLength === sessionLength) {
        await handleSessionComplete();
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!sessionId) {
    return (
      <div className={styles.root}>
        <p className={styles.title}>New session.</p>
      </div>
    );
  }

  if (!currentQuestion) {
    return (
      <div className={styles.root}>
        <p className={styles.title}>{`${
          questionsLeft === sessionLength ? 'New  session. ' : ''
        }${questionsLeft} question${questionsLeft !== 1 ? 's' : ''} left.`}</p>
      </div>
    );
  }

  const { model_intent } = currentQuestion;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
      <p className={styles.title}>Rate the response</p>
      <p className={styles.note}>Lowest rating = 1</p>
      <p className={styles.note}>Highest ratting = 10</p>

      <div className={styles.field}>
        <p className={styles.legend}>Model Intent</p>
        <p>{model_intent}</p>
      </div>

      <div className={styles.field}>
        <legend
          className={classNames(styles.legend, {
            [styles.isRequired]: errors.is_intent_correct,
          })}
        >
          Is Intent correct?
        </legend>

        {isIntentCorrectOptions.map((item) => (
          <div key={item}>
            <input
              type="radio"
              id={item}
              value={item}
              {...register('is_intent_correct', {
                required: true,
              })}
            />
            <label htmlFor={item}>{item}</label>
          </div>
        ))}
      </div>

      {isIntentCorrectValue === 'No' && (
        <FeedbackIntentSelect
          control={control}
          error={!!errors.correct_intent}
        />
      )}

      {ratingOptions.map((item) => (
        <FeedbackRating
          {...item}
          key={item.field}
          register={register}
          watch={watch}
          error={!!errors[item.field]}
        />
      ))}

      <div className={classNames(styles.field, styles.lastField)}>
        <legend
          className={classNames(styles.legend, {
            [styles.isRequired]: errors.ideal_response,
          })}
        >
          Ideal Response
        </legend>

        <TextareaAutosize
          minRows={MIN_TEXTAREA_ROWS}
          className={styles.textarea}
          placeholder="Enter ideal output"
          {...register('ideal_response', {
            validate: (value, formValues) => {
              if (value) {
                return true;
              }
              if (
                Object.values(prepareFeedbackData(formValues)).some(
                  (value) => typeof value === 'number' && value > 0 && value < 5
                )
              ) {
                return false;
              }
              return true;
            },
          })}
        />
      </div>

      <div className={styles.actions}>
        <button className={styles.submitButton} type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

export default Feedback;
